@import "../../../styles/_variables";

.connect-modal-box {
  .rodal-dialog {
    width: 637px !important;
    height: 571px !important;
    border-radius: 15px;
    padding: 0;

    @media screen and (max-width: 1699px) {
      width: 610px !important;
      height: 500px !important;
    }

    @media screen and (max-width: 1599px) {
      width: 550px !important;
      height: 440px !important;
    }

    @media screen and (max-width: 1299px) {
      width: 530px !important;
      height: 400px !important;
    }


    @media screen and (max-width: 1024px) {
      width: 95%!important;
    }
  }
}

.connect-modal-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
    padding: 15px 0;

    h3{
      font-size: 26px;
      margin: 0;
      color: #D69700;
      padding: 20px 40px 0 40px;
    }

    span{
      color: #151618;
      font-size: 30px;
      font-weight: bold;
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 30px;
    }
  }

  .main{
    display: flex;
    margin-top: 0;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 40px;

    .blockchain-block{
      width: 519px;
      height: 92px;
      border-radius: 10px;
      margin-bottom: 25px;
      position: relative;
      cursor: pointer;


      img{
        position: absolute;
      }
    }


    .blockto{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #0274FF;
      box-shadow: 0 3px 6px #0000004D;
      border-radius: 10px;
      transition: .3s;

      @media screen and (max-width: 1699px){
        height: 85px;
      }

      @media screen and (max-width: 1599px){
        height: 80px;
      }

      @media screen and (max-width: 1299px){
        height: 65px;
      }

      &:hover{
        transform: scale(1.025);
      }

      .arrow{
        width: 36px;
        margin-right: 10px;
      }

      .icon{
        top: 22px;
        left: 25px;
        width: 25%;

        @media screen and (max-width: 1299px){
          top: 16px;
        }
      }
    }

    .metamask{
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: #E5E5E5;
      box-shadow: 0 3px 6px #0000004D;
      border-radius: 10px;
      transition: .3s;

      @media screen and (max-width: 1699px){
        height: 85px;
      }

      @media screen and (max-width: 1599px){
        height: 80px;
      }


      @media screen and (max-width: 1299px){
        height: 65px;
      }

      .arrow{
        width: 36px;
        margin-right: 10px;
      }

      &:hover{
        transform: scale(1.025);
      }

      .icon{
        top: -27px;
        left: 10px;
        width: 50%;

        @media screen and (max-width: 1699px){
          top: -19px;
          left: 10px;
          width: 45%;
        }

        @media screen and (max-width: 1599px){
          top: -14px;
        }

        @media screen and (max-width: 1299px){
          top: -18px;
        }
      }
    }
  }



}