@import "../../../styles/_variables";

.collection-filter-wrapper {
  display: flex;
  flex-direction: column;

  .MuiPaper-root {
    background: $light-white-color!important;
  }

  .filter-collections {
    display: flex;
    flex-direction: column;

    .filter-collection-wrapper {
      display: flex;
      flex-direction: column;

      span {
        margin-left: auto;
        font-size: 20px;
        font-weight: bold;
        margin-right: 5px;
      }

      .filter-collection {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.active{
          color: #D69700;
        }

        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 50%;
        }

        p {
          font-size: 18px;
          font-weight: 600;
          color: $heading-dark-color;
        }

        .available{
          color: #D69700;
        }

        &:hover {
          cursor: pointer;

          span {
            color: $main-light-color;
          }
        }
      }

      .filter-collection-templates {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-left: 5px;

        .filter-collection-template {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 38px;

          &:hover {
            cursor: pointer;

            span {
              color: $main-light-color;
            }
          }

          .template-image {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-right: 10px;
          }

          .template-image-land-color {
            background: $main-light-color;
          }

          .template-image-card-color {
            background: $border-color;
          }

          .template-image-hero-color {
            background: $secondary-dark-color;
          }

          .template-image-pack-color {
            background: $dark-block-color;
          }

          p {
            font-size: 15px;
            font-weight: 600;
            color: $dark-block-color;
            margin-bottom: 5px;
          }

          span {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .filter-collections.imx-collections{
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #b6b5b5 #b6b5b5;


    .filter-collection-wrapper{
      margin-bottom: 10px;
    }
  }

  .filter-collections.imx-collections::-webkit-scrollbar {
    width: 8px;
  }

  .filter-collections.imx-collections::-webkit-scrollbar-thumb {
    background-color: #b6b5b5;
    border-radius: 20px;
  }


  @media screen and (-webkit-min-device-pixel-ratio:0) {
    .filter-collections.imx-collections {
      scrollbar-color: #b6b5b5 transparent; /* встановлює колір скроллбару */
      scrollbar-width: thin; /* встановлює ширину скроллбару */
    }
    .filter-collections.imx-collections::-webkit-scrollbar-thumb {
      background-color: #b6b5b5;
      border-radius: 20px;
      border: none; /* встановлює відсутність рамки */
    }
  }
}
